import React from "react";
import { motion } from "framer-motion";
import "./App.css";

function App() {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {/* Header */}
      <header className="header">
        <motion.h1
          className="header-text"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
        >
          Flavinom's new home is coming soon.
        </motion.h1>
      </header>

      <main className="content">
        {/* Left Section */}
        <motion.div
          className="text-section"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <div className="logo-container">
            <img src="https://ik.imagekit.io/sugarstringsai/image-removebg-preview%20(1).png?updatedAt=1732273792425" alt="Flavinom Logo" className="logo" />
          </div>
          <p>
            Backed by 35+ years of expertise, we blend tradition and innovation
            to craft exceptional flavors and fragrances that inspire.
          </p>
          <form
            action="https://gmail.us22.list-manage.com/subscribe/post?u=c98c267aa0da47ac48cd4fbf6&amp;id=44e33bdea5&amp;f_id=00d7c2e1f0"
            method="post"
            target="_blank"
            className="form"
          >
            <input
              type="email"
              name="EMAIL"
              placeholder="Enter your email here"
              required
              className="email-input"
            />
            <button type="submit" className="notify-button">
              Notify Me
            </button>
          </form>
        </motion.div>

        {/* Right Section */}
        <motion.div
          className="image-section"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <img
            src="https://images.pexels.com/photos/932577/pexels-photo-932577.jpeg"
            alt="Placeholder"
            className="placeholder-image"
          />
        </motion.div>
      </main>

      {/* Footer */}
      <footer>
        <div className="footer-content">
          <p>Contact us: +91-638-004-5832 | info@flavinom.com</p>
          <p>35 Years of Excellence in Flavors & Fragrances</p>
          <p>&copy; 2024 Flavinom. All rights reserved.</p>
        </div>
      </footer>
    </motion.div>
  );
}

export default App;
